@if (menu$ | async; as menu) {
  <header class="header" [ngClass]="{ 'is-inverse': showNavigation() }">
    <div
      class="container split"
      [attr.data-aos]="(isMobile$ | async) === false ? 'fade-down' : null"
      [attr.data-aos-duration]="(isMobile$ | async) === false ? '1000' : null"
      >
      <div class="header__brand">
        <app-logo />
      </div>
      <nav class="header-navigation" [ngClass]="{ 'is-opened': showNavigation() }">
        <ul #navigation class="header-navigation__frame">
          @for (fixedHeaderNavigationItem of fixedMenu; track fixedHeaderNavigationItem) {
            <li class="header-navigation__item">
              <a (click)="scrollNavigate(fixedHeaderNavigationItem.scrollTo)" class="text--bold">{{
                fixedHeaderNavigationItem.label | translate
              }}</a>
            </li>
          }
          @for (headerNavigationItem of menu; track headerNavigationItem) {
            <li class="header-navigation__item">
              @if (!(headerNavigationItem.url | isExtrenalLink)) {
                <a
                  [routerLink]="[headerNavigationItem.url]"
                  (click)="showNavigation.set(!showNavigation)"
                  >{{ headerNavigationItem.label | translate }}</a
                  >
                } @else {
                  <a
                    [href]="[headerNavigationItem.url]"
                    (click)="showNavigation.set(!showNavigation)"
                    target="_blank"
                    class="text--bold"
                    >{{ headerNavigationItem.label | translate }}</a
                    >
                  }
                </li>
              }
            </ul>
          </nav>
          <button
            class="nav-icon nav-icon--squeeze"
            (click)="showNavigation.set(!showNavigation())"
            [ngClass]="{ 'is-opened': showNavigation() }"
            >
            <div class="nav-icon__lines"></div>
          </button>
        </div>
      </header>
    }

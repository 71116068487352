import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { AppConfig } from '@core/models';

fetch(`./assets/config/default.config.json?date=${new Date().valueOf()}`)
  .then((response) => response.json())
  .then((config: AppConfig) => {
    bootstrapApplication(AppComponent, appConfig(config)).catch((err) => console.error(err));
  });

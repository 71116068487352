<footer
  class="footer"
  [attr.data-aos]="(isMobile$ | async) === false ? 'fade-up' : null"
  [attr.data-aos-duration]="(isMobile$ | async) === false ? '1000' : null"
  [attr.data-aos-delay]="(isMobile$ | async) === false ? '250' : null"
  >
  <div class="container">
    <div class="footer__section footer__section--top">
      <div class="footer__brand">
        <app-logo />
        <p>{{ 'FOOTER_TRADEMARK_TEXT' | translate }}</p>
        <div class="wrapper--social">
          <app-social-channels />
        </div>
      </div>
      <nav class="footer__menu">
        @for (category of footerMenu$ | async; track category) {
          <div class="footer__column footer-collapse">
            <p class="footer__heading footer__heading--collapsable" (click)="onCollapseTitleClick($event)">
              <span>{{ category.label }}</span>
              <i class="job-offer__arrow teq-arrow-right2"></i>
            </p>
            <ul class="footer-collapse__frame">
              @for (categoryItem of category.children; track categoryItem) {
                <li class="footer-collapse__item">
                  @if (!(categoryItem.url | isExtrenalLink)) {
                    <a
                      [routerLink]="categoryItem.url"
                      >{{ categoryItem.label | translate }}</a
                      >
                    } @else {
                      <a [href]="categoryItem.url" target="_blank" rel="noopener">{{ categoryItem.label }}</a>
                    }
                  </li>
                }
              </ul>
            </div>
          }
        </nav>
        <div class="footer__table-finder">
          <p>{{ 'FOOTER_TABLE_FINDER_DESCRIPTION' | translate }}</p>
          <a class="button button--primary split" href="https://eu.teqers.com/table-finder" target="_blank"
            >{{ 'FOOTER_TABLE_FINDER_BUTTON' | translate }}
            <i class="teq-arrow-right2"></i>
          </a>
        </div>
      </div>
      <div class="footer__section footer__section--bottom">
        <div class="wrapper--legal">
          <p>{{ 'TEQBALL_ALL_RIGHTS_RESERVED' | translate : { now: this.now } }}</p>
          @for (legalMenuItem of legalMenu$ | async; track legalMenuItem) {
            @if (!(legalMenuItem.url | isExtrenalLink)) {
              <a
                [routerLink]="legalMenuItem.url"
                >{{ legalMenuItem.label | translate }}</a
                >
              } @else {
                <a [href]="legalMenuItem.url" target="_blank" rel="noopener">{{ legalMenuItem.label }}</a>
              }
            }
          </div>
        </div>
      </div>
    </footer>

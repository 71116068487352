import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from '@app/app.routes';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '@core/models';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButterTranslateLoader, LocalizationService } from '@core/localization';
import { TranslateModule, TranslateLoader, InterpolatableTranslationObject } from '@ngx-translate/core';
import { teqSiteInterceptor } from '@core/interceptors';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { provideClientHydration } from '@angular/platform-browser';

export const appConfig: (config: AppConfig) => ApplicationConfig = (config: AppConfig) => ({
  providers: [
    provideZoneChangeDetection({ eventCoalescing: false }),
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptors([teqSiteInterceptor])),
    {
      provide: APP_CONFIG,
      useValue: config,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: LocalizationService) => (): Observable<InterpolatableTranslationObject> => service.init(),
      multi: true,
      deps: [LocalizationService],
    },
    CookieService,
    importProvidersFrom(
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: ButterTranslateLoader,
          deps: [HttpClient, APP_CONFIG],
        },
      })
    ),
  ],
});

import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuService } from '@app/layout/services';
import { Breakpoints, MenuItem } from '@app/layout/models';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT, NgClass, AsyncPipe } from '@angular/common';
import { Router, Scroll, RouterLink } from '@angular/router';
import { ExternalLinkPipe } from '@shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from '@shared/components';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, RouterLink, AsyncPipe, TranslateModule, ExternalLinkPipe, LogoComponent],
})
export class HeaderComponent implements OnInit {
  @ViewChild('navigation')
  public navigationRef: ElementRef;
  public isMobile$: Observable<boolean>;

  public showNavigation = signal(false);
  public menu$: Observable<MenuItem[]> | undefined;
  public fixedMenu = [
    {
      label: 'FIX_NAVIGATION_TEQBALL_SPORT',
      scrollTo: 'fiteq',
    },
    {
      label: 'FIX_NAVIGATION_TEQSHOP',
      scrollTo: 'teqers',
    },
    {
      label: 'FIX_NAVIGATION_TEQSPORTS',
      scrollTo: 'sports',
    },
  ];

  constructor(
    private router: Router,
    private menuService: MenuService,
    public breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isMobile$ = this.breakpointObserver
      .observe(`(max-width: ${Breakpoints.DESK}px)`)
      .pipe(map((breakpoints) => breakpoints.matches));

    this.router.events.subscribe((event: any) => {
      if (event instanceof Scroll && event.anchor) {
        setTimeout(() => {
          this.scroll('#' + event.anchor);
        }, 100);
      }
    });
  }

  ngOnInit(): void {
    this.menu$ = this.menuService.getMenuItems();
  }

  public scrollNavigate(scrollTo: string): void {
    this.showNavigation.set(!this.showNavigation);
    this.router.navigate(['/'], { fragment: scrollTo });
    this.scroll('#' + scrollTo);
  }

  private scroll(query: string): void {
    const targetElement = this.document.querySelector(query);
    if (!targetElement) {
      window.scrollTo(0, 0);
    } else if (!this.isInViewport(targetElement)) {
      targetElement.scrollIntoView();
    }
  }

  private isInViewport = (elem: any): boolean => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= window.innerHeight &&
      bounding.right <= window.innerWidth
    );
  };
}

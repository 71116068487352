import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@core/models';

export const teqSiteInterceptor: HttpInterceptorFn = (request, next) => {
  const config = inject<AppConfig>(APP_CONFIG);

  if (request.url.includes(config.apiUrl) || request.url.includes(config.butterApiUrl)) {
    request = request.clone({ setHeaders: { 'X-TEQ-SITE': config.teqSiteId } });
  }

  return next(request);
};
